/*------------------------------------*\
    Footer
\*------------------------------------*/

#form-nl {
  width: 100%;
  margin: 0 auto;

  input {
    height: 60px;
    background: $grey;
    padding: 0 30px;
    width: 100%;
    max-width: 100%;
    margin-right: 40px;
    float: left;
    font-size: 16px;

    &::placeholder {
      color: $black;
      font-weight: 300;
    }
  }
  button {
    @extend .btn;

    background: $black;
    color: $white;
    text-align: center;
    width: 100%;

    &:hover {
      background: $blue;
    }
  }

  @media #{md("small")} {
    max-width: 800px;

    button {
      float: right;
      width: auto;
    }

    input {
      max-width: 480px;
    }
  }
}

footer {
  z-index: 2;

  .footer-inner {
    padding: 0 2%;
  }

  .signature {
    text-align: center;
    width: 20%;
    display: block;
    margin: 30px auto 0 auto;
    font-size: 22px;
  }

  .social-container {
    width: 40%;

    &.f-right {
      text-align: right;
    }
  }

  @media #{md("smaller", "max")} {
    .social-container {
      width: 100%;
      float: none;
      text-align: center !important;
      left: auto;
      right: auto;
    }

    .signature {
      float: none;
      width: 100%;
      margin-bottom: 30px;
    }
  }

  @media #{md("medium")} {
    .footer-inner {
      padding: 0 50px;
    }
    .signature {
      width: 50%;
      font-size: 28px;
    }

    .social-container {
      width: 25%;
    }
  }
}
