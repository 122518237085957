/*---------------------------------------*\
    Sélection
\*---------------------------------------*/

::-moz-selection {
  text-shadow: none;
  color: $selection-color;
  background: $selection-bg;
}

::selection {
  text-shadow: none;
  color: $selection-color;
  background: $selection-bg;
}

/*---------------------------------------*\
    Réglages globaux
\*---------------------------------------*/

*,
*:after,
*:before {
  position: relative;
  box-sizing: inherit;
  outline: none;
}

html {
  box-sizing: border-box;
  font-size: $font-size;

  // Better font rendering for mac
  &.mac {
    @include antialiased;
  }

  // Media queries
  @media #{md("medium")} {
    font-size: $font-size + 1;
  }

  @media #{md("large")} {
    font-size: $font-size + 2;
  }

  @media #{md("larger")} {
    font-size: $font-size + 3;
  }

  @media #{md("largest")} {
    font-size: $font-size + 4;
  }
}

body {
  font-family: $font-family;
  font-size: 1em;
  color: $font-color;
}

/*------------------------------------*\
    Signature Studio Meta
\*------------------------------------*/

.studio-meta {
  display: block;
  font-size: 14px;

  overflow: hidden;

  &:hover {
    .icon_hover {
      width: 100%;
    }
  }

  span {
    display: inline-block;
  }
}

.single #content {
  overflow-x: hidden;
}

/*------------------------------------*\
    Sections
\*------------------------------------*/

.normal-section {
  padding: 50px 0;
  @media #{md("medium")} {
    padding: 150px 0;
  }
}

.medium-section {
  padding: 75px 0;
  @media #{md("medium")} {
    padding: 75px 0;
  }
}

/*------------------------------------*\
    Misc Helpers
\*------------------------------------*/

.f-left {
  float: left;
}
.f-right {
  float: right;
}

.medium {
  font-weight: $medium;
}

.sans {
  font-family: $sans;
}

.serif {
  font-family: $serif;
  font-weight: 300;
}

.surtitle {
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 20px;

  @media #{md("medium")} {
    margin-bottom: 40px;
  }
}

.content {
  font-size: 16px;
  color: $black-light;

  h1 {
    font-size: 70px;
    margin-bottom: 30px;
  }

  h2 {
    text-align: center;
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 30px;

    &:after {
      content: '';
      display: block;

      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin: 20px auto;
      background: $blue;
    }
  }

  h3 {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 300;
    margin-bottom: 20px;
  }

  .intro {
    margin-bottom: 50px;
  }

  p {
    font-size: 18px;
    line-height: 30px;
    color: $black-light;
    font-weight: 300;

    strong {
      font-weight: 600;
    }

    a {
      color: $black-light;
      transition: 0.3s color $out-cubic;

      &:hover {
        color: $blue;
      }
    }
  }

  iframe {
    width: 100%;
    height: 300px;
  }

  img {
    display: block;

    width: 75%;
    height: auto;

    margin: 80px 0;
  }

  p img {
    width: 100%;
  }

  @media #{md("medium")} {
    p {
      font-size: 24px;
      line-height: 44px;
    }
    h1 {
      font-size: 124px;
      margin-bottom: 75px;
    }

    h2 {
      margin-bottom: 100px;
      font-size: 50px;
      margin-bottom: 50px;
    }

    h3 {
      font-size: 16px;
      letter-spacing: 2px;
      margin-bottom: 60px;
    }

    iframe {
      height: 500px;
    }
  }

  .subtitle {
  }

  figure {
  }
  blockquote {
  }
  blockquote p {
  }

  p {
    a {
      color: $blue;
      font-weight: bold;

      &:hover {
        color: $black-light;
      }
    }
    strong {
    }
  }

  ul {
    li {
      font-size: 18px;
      font-weight: 300;
      padding-left: 20px;

      & + li {
        margin-top: 10px;
      }

      &:before {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        position: absolute;
        left: 0;
        top: 9px;
        background: $blue;
        border-radius: 50%;
      }
    }
  }
}

.pb-content + .pb-content,
.pb-content + .pb-slider,
.pb-content + .pb-quote,
.pb-slider + .pb-quote,
.pb-slider + .pb-content,
.pb-quote + .pb-slider,
.pb-quote + .pb-content {
  margin-top: 70px;
}

.mb-20 {
  margin-bottom: 20px;
}
.mb-40 {
  margin-bottom: 20px;
  @media #{md("medium")} {
    margin-bottom: 40px;
  }
}
.mb-60 {
  margin-bottom: 30px;
  @media #{md("medium")} {
    margin-bottom: 60px;
  }
}
.mb-90 {
  margin-bottom: 0px;
  @media #{md("small")} {
    margin-bottom: 40px;
  }

  @media #{md("medium")} {
    margin-bottom: 90px;
  }
}
.mb-120 {
  margin-bottom: 40px;
  @media #{md("medium")} {
    margin-bottom: 120px;
  }
}
.mr-20 {
  margin-right: 20px;
}
.mr-40 {
  margin-right: 40px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-40 {
  padding-top: 40px;
}

.full-pic {
  width: 100%;
  height: auto;
  display: block;
}
.shadow {
  box-shadow: $box-shadow;
}

/*------------------------------------*\
    Base couleurs
\*------------------------------------*/

.blue {
  color: $blue;
}
.blue-light {
  color: $blue_light;
}
.white {
  color: $white;
}
.grey {
  color: $grey;
}
.black {
  color: $black;
}

.bg-blue {
  background: $blue;
}
.bg-black {
  background: $black;
}
.bg-white {
  background: $white;
}
.bg-grey {
  background: $grey-bg;
}
.bg-blue-light {
  background: $blue_light;
}

.line-grid {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: none;

  .v-line {
    height: 100vh;

    &:before {
      content: '';
      width: 1px;
      display: block;
      height: 100vh;
      left: 0;
      position: absolute;
      background: #c1c1c1;
      opacity: 0.2;
    }
    &:nth-of-type(3) {
      &:before {
        margin-left: -1px;
      }
    }
    &:last-of-type:after {
      content: '';
      width: 1px;
      display: block;
      height: 100vh;
      position: absolute;
      background: #c1c1c1;
      opacity: 0.3;
      right: 0;
    }
  }

  @media #{md("small")} {
    display: block;
  }
}

.medium-title {
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 30px;

  &:after {
    content: '';
    display: block;

    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 20px auto;
  }

  &.black {
    &:after {
      background: $blue;
    }
  }
  &.white {
    &:after {
      background: $white;
    }
  }

  @media #{md("medium")} {
    margin-bottom: 100px;
    font-size: 50px;
    margin-bottom: 50px;
  }
}
