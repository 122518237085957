/*------------------------------------*\
    Buttons
\*------------------------------------*/

.link {
  text-transform: uppercase;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: 5px;
  font-weight: 400;
  transition: 0.6s opacity $out-cubic;

  &:hover {
    opacity: 0.6;
  }
}

.btn {
  height: 60px;
  line-height: 60px;
  text-transform: uppercase;
  font-family: $sans;
  font-weight: 300;
  padding: 0 60px;
  font-size: 12px;
  letter-spacing: 4px;
  display: inline-block;
  text-decoration: none;
  transition: 0.6s color $out-cubic, 0.6s background $out-cubic;
  border: 1px solid currentColor;
}

.btn.bg-black:hover {
  background: white;
  color: $black;
}

.btn.bg-white:hover {
  background: $black;
  color: white;
}
