@charset "UTF-8";
/*---------------------------------------*\
    Styles et réglages globaux
\*---------------------------------------*/
/*------------------------------------*\
    Easing
\*------------------------------------*/
/*------------------------------------*\
		CSS Reset

	http://meyerweb.com/eric/tools/css/reset/
  v2.0 | 20110126
  License: none (public domain)
\*------------------------------------*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video,
button, input, select {
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  font: inherit;
  font-size: 100%;
  border: 0;
}

/* HTML5 display-role reset for older browsers */
main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

q,
blockquote {
  quotes: none;
}
q:after, q:before,
blockquote:after,
blockquote:before {
  content: "";
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button,
input {
  font: inherit;
}
.mac button,
.mac input {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-osx-font-smoothing: antialiased;
}

button {
  cursor: pointer;
}

/*------------------------------------*\
		Functions
\*------------------------------------*/
/**
 * A function helper to avoid having to type `map-get($z-layers, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 * @param  {string} $layer The name of the z-index
 * @param  {number} $var   The modifier if needed
 * @return {number}        The corresponding z-index based on the $z-layers var
 */
/**
 * Remove units from the given number
 * @param  {number} $number The number with units
 * @return {number}
 */
/**
 * Create variables for the media queries
 * @param  {string} $breakpoint The breakpoint
 * @param  {string} $type       Type of media query (min or max)
 * @param  {string} $unit       The unit for the media queries (em or px)
 * @return {string}             A media query expression
 */
/**
 * Slightly lighten a color
 * @access public
 * @param {Color} $color - color to tint
 * @param {Number} $percentage - percentage of `$color` in returned color
 * @return {Color}
 */
/**
 * Slightly darken a color
 * @access public
 * @param  {color}  $color       color to shade
 * @param  {number} $percentage  percentage of `$color` in returned color
 * @return {Color}
 */
/**
 * Power function
 * @param  {number} $number Number to apply power
 * @param  {number} $exp    The exponant for the power
 * @return {number}         The powered number
 */
/**
 * Factorial function
 * @param  {number} $number The number to factorize
 * @return {number}         The factorised number
 */
/**
 * Pi reference
 * @return {number} Retrun PI with 11 decimals
 */
/**
 * Convert deg to rad
 * @param  {string} $angle The angle to convert
 * @return {number}        The unitless angle converted to rad
 */
/**
 * Calculate the sinus of an angle
 * @param  {string} $angle The angle to compute
 * @return {number}        The sinus of the given angle
 */
/**
 * Calculate the cosinus of an angle
 * @param  {string} $angle The angle to compute
 * @return {number}        The cosinus of the given angle
 */
/**
 * Calculate the tangent of an angle
 * @param  {string} $angle The angle to compute
 * @return {number}        The tangent of the given angle
 */
/*---------------------------------------*\
		Positions & dispositions
\*---------------------------------------*/
/*---------------------------------------*\
		Typographie
\*---------------------------------------*/
/*---------------------------------------*\
		Triangles
\*---------------------------------------*/
/*---------------------------------------*\
		SVG Responsive
\*---------------------------------------*/
/*
Utilitaire pour le padding hack qui permet d'avoir des fichiers `*.svg` responsive.
Plus d'infos ici : http://tympanus.net/codrops/2014/08/19/making-svgs-responsive-with-css/
*/
/*------------------------------------*\
		Gradients
\*------------------------------------*/
/**
 * Mixin printing a linear-gradient
 * as well as a plain color fallback
 * and the `-webkit-` prefixed declaration
 * @param {String | List | Angle} $direction - Linear gradient direction
 * @param {Arglist} $color-stops - List of color-stops composing the gradient
 */
/*------------------------------------*/
/*    Fontface declaration
/*------------------------------------*/
@font-face {
  font-family: "Circular";
  src: url("../../static/fonts/CircularStd-Medium.woff2") format("woff2"), url("../../static/fonts/CircularStd-Medium.woff") format("woff"), url("../../static/fonts/CircularStd-Medium.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Circular";
  src: url("../../static/fonts/CircularStd-Book.woff2") format("woff2"), url("../../static/fonts/CircularStd-Book.woff") format("woff"), url("../../static/fonts/CircularStd-Book.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Circular";
  src: url("../../static/fonts/CircularStd-Bold.woff2") format("woff2"), url("../../static/fonts/CircularStd-Bold.woff") format("woff"), url("../../static/fonts/CircularStd-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Crimson";
  src: url("../../static/fonts/CrimsonText-Italic.woff2") format("woff2"), url("../../static/fonts/CrimsonText-Italic.woff") format("woff"), url("../../static/fonts/CrimsonText-Italic.otf") format("opentype");
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: "Gorgeous";
  src: url("../../static/fonts/Gorgeous-Bold.woff2") format("woff2"), url("../../static/fonts/Gorgeous-Bold.woff") format("woff"), url("../../static/fonts/Gorgeous-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}
/*---------------------------------------*\
		Debug
\*---------------------------------------*/
/*
Une condition dépendante de la variable `$debug` qui permet d'afficher les contours de tous les élements du DOM.
*/
[debug] {
  display: none;
}

/*------------------------------------*\
		Positionnement & Display
\*------------------------------------*/
.none {
  display: none;
}

.table {
  display: table;
}

.cell {
  display: table-cell;
}

.bl {
  display: block;
}

.inlbl {
  display: inline-block;
}

.abs {
  position: absolute;
}

.rel {
  position: relative;
}

.cf:after, form .gform_body:after, .social-list:after {
  content: "";
  clear: both;
  display: block;
}

.left {
  float: left;
}

.right {
  float: right;
}

/*------------------------------------*\
		Typographie
\*------------------------------------*/
.tac {
  text-align: center;
}

.tal {
  text-align: left;
}

.tar {
  text-align: right;
}

@media (min-width: 62em) {
  .tar-d {
    text-align: right;
  }
}

.list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-osx-font-smoothing: antialiased;
}

.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

/*---------------------------------------*\
    Sélection
\*---------------------------------------*/
::-moz-selection {
  text-shadow: none;
  color: #fff;
  background: #16097b;
}

::selection {
  text-shadow: none;
  color: #fff;
  background: #16097b;
}

/*---------------------------------------*\
    Réglages globaux
\*---------------------------------------*/
*,
*:after,
*:before {
  position: relative;
  box-sizing: inherit;
  outline: none;
}

html {
  box-sizing: border-box;
  font-size: 16px;
}
html.mac {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-osx-font-smoothing: antialiased;
}
@media (min-width: 62em) {
  html {
    font-size: 17px;
  }
}
@media (min-width: 80em) {
  html {
    font-size: 18px;
  }
}
@media (min-width: 90em) {
  html {
    font-size: 19px;
  }
}
@media (min-width: 120em) {
  html {
    font-size: 20px;
  }
}

body {
  font-family: "Circular", "Helvetica Neue", Arial, sans-serif;
  font-size: 1em;
  color: #6d6d6d;
}

/*------------------------------------*\
    Signature Studio Meta
\*------------------------------------*/
.studio-meta {
  display: block;
  font-size: 14px;
  overflow: hidden;
}
.studio-meta:hover .icon_hover {
  width: 100%;
}
.studio-meta span {
  display: inline-block;
}

.single #content {
  overflow-x: hidden;
}

/*------------------------------------*\
    Sections
\*------------------------------------*/
.normal-section {
  padding: 50px 0;
}
@media (min-width: 62em) {
  .normal-section {
    padding: 150px 0;
  }
}

.medium-section {
  padding: 75px 0;
}
@media (min-width: 62em) {
  .medium-section {
    padding: 75px 0;
  }
}

/*------------------------------------*\
    Misc Helpers
\*------------------------------------*/
.f-left {
  float: left;
}

.f-right {
  float: right;
}

.medium {
  font-weight: 500;
}

.sans {
  font-family: "Circular", "Helvetica Neue", Arial, sans-serif;
}

.serif {
  font-family: "Crimson", "Georgia", Arial, sans-serif;
  font-weight: 300;
}

.surtitle {
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 20px;
}
@media (min-width: 62em) {
  .surtitle {
    margin-bottom: 40px;
  }
}

.content {
  font-size: 16px;
  color: #282828;
}
.content h1 {
  font-size: 70px;
  margin-bottom: 30px;
}
.content h2 {
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 30px;
}
.content h2:after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 20px auto;
  background: #16097b;
}
.content h3 {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  margin-bottom: 20px;
}
.content .intro {
  margin-bottom: 50px;
}
.content p {
  font-size: 18px;
  line-height: 30px;
  color: #282828;
  font-weight: 300;
}
.content p strong {
  font-weight: 600;
}
.content p a {
  color: #282828;
  transition: 0.3s color cubic-bezier(0.215, 0.61, 0.355, 1);
}
.content p a:hover {
  color: #16097b;
}
.content iframe {
  width: 100%;
  height: 300px;
}
.content img {
  display: block;
  width: 75%;
  height: auto;
  margin: 80px 0;
}
.content p img {
  width: 100%;
}
@media (min-width: 62em) {
  .content p {
    font-size: 24px;
    line-height: 44px;
  }
  .content h1 {
    font-size: 124px;
    margin-bottom: 75px;
  }
  .content h2 {
    margin-bottom: 100px;
    font-size: 50px;
    margin-bottom: 50px;
  }
  .content h3 {
    font-size: 16px;
    letter-spacing: 2px;
    margin-bottom: 60px;
  }
  .content iframe {
    height: 500px;
  }
}
.content p a {
  color: #16097b;
  font-weight: bold;
}
.content p a:hover {
  color: #282828;
}
.content ul li {
  font-size: 18px;
  font-weight: 300;
  padding-left: 20px;
}
.content ul li + li {
  margin-top: 10px;
}
.content ul li:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  position: absolute;
  left: 0;
  top: 9px;
  background: #16097b;
  border-radius: 50%;
}

.pb-content + .pb-content,
.pb-content + .pb-slider,
.pb-content + .pb-quote,
.pb-slider + .pb-quote,
.pb-slider + .pb-content,
.pb-quote + .pb-slider,
.pb-quote + .pb-content {
  margin-top: 70px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 20px;
}
@media (min-width: 62em) {
  .mb-40 {
    margin-bottom: 40px;
  }
}

.mb-60 {
  margin-bottom: 30px;
}
@media (min-width: 62em) {
  .mb-60 {
    margin-bottom: 60px;
  }
}

.mb-90 {
  margin-bottom: 0px;
}
@media (min-width: 48em) {
  .mb-90 {
    margin-bottom: 40px;
  }
}
@media (min-width: 62em) {
  .mb-90 {
    margin-bottom: 90px;
  }
}

.mb-120 {
  margin-bottom: 40px;
}
@media (min-width: 62em) {
  .mb-120 {
    margin-bottom: 120px;
  }
}

.mr-20 {
  margin-right: 20px;
}

.mr-40 {
  margin-right: 40px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-40 {
  padding-top: 40px;
}

.full-pic {
  width: 100%;
  height: auto;
  display: block;
}

.shadow {
  box-shadow: 5px 8px 30px rgba(0, 0, 0, 0.15);
}

/*------------------------------------*\
    Base couleurs
\*------------------------------------*/
.blue {
  color: #16097b;
}

.blue-light {
  color: #2c3240;
}

.white {
  color: #fff;
}

.grey {
  color: #efeff2;
}

.black {
  color: #1f2020;
}

.bg-blue {
  background: #16097b;
}

.bg-black {
  background: #1f2020;
}

.bg-white {
  background: #fff;
}

.bg-grey {
  background: #f4f7fb;
}

.bg-blue-light {
  background: #2c3240;
}

.line-grid {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: none;
}
.line-grid .v-line {
  height: 100vh;
}
.line-grid .v-line:before {
  content: "";
  width: 1px;
  display: block;
  height: 100vh;
  left: 0;
  position: absolute;
  background: #c1c1c1;
  opacity: 0.2;
}
.line-grid .v-line:nth-of-type(3):before {
  margin-left: -1px;
}
.line-grid .v-line:last-of-type:after {
  content: "";
  width: 1px;
  display: block;
  height: 100vh;
  position: absolute;
  background: #c1c1c1;
  opacity: 0.3;
  right: 0;
}
@media (min-width: 48em) {
  .line-grid {
    display: block;
  }
}

.medium-title {
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 30px;
}
.medium-title:after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 20px auto;
}
.medium-title.black:after {
  background: #16097b;
}
.medium-title.white:after {
  background: #fff;
}
@media (min-width: 62em) {
  .medium-title {
    margin-bottom: 100px;
    font-size: 50px;
    margin-bottom: 50px;
  }
}

/*
Typographie
===========

Règles de base pour tout le site (h1, h2, blockquote, etc).

-->    http://type-scale.com/    <--

Pratique pour générer des rythmes typographiques rapidement
*/
body {
  line-height: 1.45;
}

p,
ol,
blockquote {
  margin-bottom: 1.3em;
}

ul {
  margin-bottom: 0;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  line-height: 1.2;
  font-weight: 700;
}

small {
  font-size: 0.75em;
}

sup {
  vertical-align: super;
  font-size: 0.75em;
}

/*------------------------------------*\
    Grid system (Bootstrap)
\*------------------------------------*/
.container {
  width: 86%;
  max-width: 960px;
  margin-right: auto;
  margin-left: auto;
  z-index: 2;
  padding-right: 0.5em;
  padding-left: 0.5em;
}
@media (min-width: 48em) {
  .container {
    padding-right: 0.625em;
    padding-left: 0.625em;
  }
}
@media (min-width: 62em) {
  .container {
    width: 86%;
    padding-right: 0.75em;
    padding-left: 0.75em;
  }
}

.row {
  margin-right: -0.5em;
  margin-left: -0.5em;
}
.row:after {
  content: "";
  clear: both;
  display: block;
}
@media (min-width: 48em) {
  .row {
    margin-right: -0.625em;
    margin-left: -0.625em;
  }
}
@media (min-width: 62em) {
  .row {
    margin-right: -0.75em;
    margin-left: -0.75em;
  }
}

.container,
[class*=col-xs-],
[class*=col-s-],
[class*=col-m-],
[class*=col-n-],
[class*=col-l-],
[class*=col-xl-] {
  min-height: 1px;
}

[class*=col-xs-],
[class*=col-s-],
[class*=col-m-],
[class*=col-n-],
[class*=col-l-],
[class*=col-xl-] {
  float: left;
}
[class*=col-xs-].right,
[class*=col-s-].right,
[class*=col-m-].right,
[class*=col-n-].right,
[class*=col-l-].right,
[class*=col-xl-].right {
  float: right;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-11 {
  width: 91.66666667%;
}

.col-xs-10 {
  width: 83.33333333%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.66666667%;
}

.col-xs-7 {
  width: 58.33333333%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.66666667%;
}

.col-xs-4 {
  width: 33.33333333%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.66666667%;
}

.col-xs-1 {
  width: 8.33333333%;
}

.col-xs-0 {
  width: 0;
}

@media (min-width: 30em) {
  .col-s-12 {
    width: 100%;
  }

  .col-s-11 {
    width: 91.66666667%;
  }

  .col-s-10 {
    width: 83.33333333%;
  }

  .col-s-9 {
    width: 75%;
  }

  .col-s-8 {
    width: 66.66666667%;
  }

  .col-s-7 {
    width: 58.33333333%;
  }

  .col-s-6 {
    width: 50%;
  }

  .col-s-5 {
    width: 41.66666667%;
  }

  .col-s-4 {
    width: 33.33333333%;
  }

  .col-s-3 {
    width: 25%;
  }

  .col-s-2 {
    width: 16.66666667%;
  }

  .col-s-1 {
    width: 8.33333333%;
  }

  .col-s-0 {
    width: 0;
  }
}
@media (min-width: 48em) {
  .col-m-12 {
    width: 100%;
  }

  .col-m-11 {
    width: 91.66666667%;
  }

  .col-m-10 {
    width: 83.33333333%;
  }

  .col-m-9 {
    width: 75%;
  }

  .col-m-8 {
    width: 66.66666667%;
  }

  .col-m-7 {
    width: 58.33333333%;
  }

  .col-m-6 {
    width: 50%;
  }

  .col-m-5 {
    width: 41.66666667%;
  }

  .col-m-4 {
    width: 33.33333333%;
  }

  .col-m-3 {
    width: 25%;
  }

  .col-m-2 {
    width: 16.66666667%;
  }

  .col-m-1 {
    width: 8.33333333%;
  }

  .col-m-0 {
    width: 0;
  }
}
@media (min-width: 62em) {
  .col-n-12 {
    width: 100%;
  }

  .col-n-11 {
    width: 91.66666667%;
  }

  .col-n-10 {
    width: 83.33333333%;
  }

  .col-n-9 {
    width: 75%;
  }

  .col-n-8 {
    width: 66.66666667%;
  }

  .col-n-7 {
    width: 58.33333333%;
  }

  .col-n-6 {
    width: 50%;
  }

  .col-n-5 {
    width: 41.66666667%;
  }

  .col-n-4 {
    width: 33.33333333%;
  }

  .col-n-3 {
    width: 25%;
  }

  .col-n-2 {
    width: 16.66666667%;
  }

  .col-n-1 {
    width: 8.33333333%;
  }

  .col-n-0 {
    width: 0;
  }
}
@media (min-width: 80em) {
  .col-l-12 {
    width: 100%;
  }

  .col-l-11 {
    width: 91.66666667%;
  }

  .col-l-10 {
    width: 83.33333333%;
  }

  .col-l-9 {
    width: 75%;
  }

  .col-l-8 {
    width: 66.66666667%;
  }

  .col-l-7 {
    width: 58.33333333%;
  }

  .col-l-6 {
    width: 50%;
  }

  .col-l-5 {
    width: 41.66666667%;
  }

  .col-l-4 {
    width: 33.33333333%;
  }

  .col-l-3 {
    width: 25%;
  }

  .col-l-2 {
    width: 16.66666667%;
  }

  .col-l-1 {
    width: 8.33333333%;
  }

  .col-l-0 {
    width: 0;
  }
}
@media (min-width: 90em) {
  .col-xl-12 {
    width: 100%;
  }

  .col-xl-11 {
    width: 91.66666667%;
  }

  .col-xl-10 {
    width: 83.33333333%;
  }

  .col-xl-9 {
    width: 75%;
  }

  .col-xl-8 {
    width: 66.66666667%;
  }

  .col-xl-7 {
    width: 58.33333333%;
  }

  .col-xl-6 {
    width: 50%;
  }

  .col-xl-5 {
    width: 41.66666667%;
  }

  .col-xl-4 {
    width: 33.33333333%;
  }

  .col-xl-3 {
    width: 25%;
  }

  .col-xl-2 {
    width: 16.66666667%;
  }

  .col-xl-1 {
    width: 8.33333333%;
  }

  .col-xl-0 {
    width: 0;
  }
}
/*------------------------------------*\
    Header
\*------------------------------------*/
.main-header {
  background: #16097b;
  height: 60vh;
  width: 100%;
  z-index: 2;
  max-height: 780px;
}
.main-header:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url(/wp-content/themes/labelleintention/assets/img/layout//bg_dots.png) repeat top left;
  background-size: 30px 30px;
  opacity: 0.25;
  z-index: 1;
}
.main-header:after {
  content: "";
  display: block;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(/wp-content/themes/labelleintention/assets/img/layout//circle_header.png) no-repeat center center;
  background-size: 200px 200px;
  z-index: -1;
}
.main-header .table {
  height: 100%;
  width: 100%;
}
.main-header .table .cell {
  height: 100%;
  vertical-align: middle;
}
.main-header .col-left {
  padding: 30px;
  z-index: 1;
}
.main-header .col-left .col-left-inner {
  height: 100%;
}
.main-header .col-left .btn, .main-header .col-left .gform_footer [type=submit], .gform_footer .main-header .col-left [type=submit], .main-header .col-left #form-nl button, #form-nl .main-header .col-left button {
  width: 205px;
  padding: 0;
  text-align: center;
  margin-top: 30px;
}
@media (min-width: 62em) {
  .main-header .col-left {
    padding: 50px;
  }
  .main-header .col-left .col-left-bottom-container {
    position: absolute;
    bottom: 0;
  }
}
.main-header .col-right {
  height: 300px;
}
.main-header .col-right .col-right-inner {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.main-header .col-right .col-right-inner .col-right-content {
  z-index: 1;
  height: 100%;
}
.main-header .col-right .col-right-inner .col-right-content .table,
.main-header .col-right .col-right-inner .col-right-content .cell {
  height: 100%;
}
.main-header .col-right .col-right-inner .col-right-content .cell {
  vertical-align: middle;
}
.main-header .col-right .col-right-inner:after {
  content: "";
  display: block;
  background: #16097b;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 0;
  opacity: 0.4;
}
.main-header .header-title {
  text-align: center;
  width: 100%;
  font-size: 60px;
  line-height: 64px;
}
@media (min-width: 48em) {
  .main-header .header-title {
    font-size: 100px;
    line-height: 96px;
  }
}
@media (min-width: 48em) {
  .main-header .col-right {
    height: 450px;
  }
}
@media (min-width: 62em) {
  .main-header {
    max-height: none;
    height: 100vh;
  }
  .main-header .col-left {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 305px;
  }
  .main-header .col-right {
    position: absolute;
    top: 0;
    left: 305px;
    width: calc(100% - 305px);
    height: 100%;
  }
  .main-header:after {
    width: 536px;
    height: 536px;
    background-size: 536px 536px;
  }
}
@media (min-width: 80em) {
  .main-header .header-title {
    font-size: 124px;
    line-height: 120px;
  }
}

@media (max-width: 62em) {
  .col-left-bottom-container {
    text-align: center;
  }
}

.logo-small {
  z-index: 102;
  display: block;
  position: absolute;
  top: 30px;
  left: 30px;
  width: 60px;
}
.logo-small img {
  width: 100%;
  height: auto;
  display: block;
}
@media (min-width: 48em) {
  .logo-small {
    top: 50px;
    left: 50px;
    width: 88px;
  }
}

.main-logo-container {
  width: 90%;
  max-width: 150px;
  margin: 0 auto;
}
.main-logo-container:before {
  content: "";
  display: block;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -100px;
  background: url(/wp-content/themes/labelleintention/assets/img/layout//circle_header.png) no-repeat center center;
  background-size: 200px 200px;
}
.main-logo-container img {
  width: 100%;
  max-width: 295px;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  display: block;
}
@media (min-width: 48em) {
  .main-logo-container {
    max-width: 420px;
  }
  .main-logo-container:before {
    background-size: 536px 536px;
  }
}

.scrollto {
  display: block;
  position: absolute;
  bottom: 0;
  z-index: 2;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 3px;
  font-weight: bold;
  left: 50%;
  margin-left: -50px;
  width: 98px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
}
.scrollto:hover:after {
  transform: scaleY(0.5) translateY(10px);
}
.scrollto span {
  margin-bottom: 10px;
  display: block;
}
.scrollto:after {
  content: "";
  display: block;
  width: 1px;
  background: #fff;
  height: 20px;
  opacity: 0.5;
  margin: 0 auto;
  transform-origin: bottom;
  transition: 0.2s all cubic-bezier(0.075, 0.82, 0.165, 1);
}
@media (min-width: 48em) {
  .scrollto {
    font-size: 12px;
  }
  .scrollto span {
    margin-bottom: 20px;
  }
  .scrollto:after {
    height: 80px;
  }
}

.main-logo {
  margin-bottom: 30px;
}

.signature {
  font-size: 20px;
  text-align: center;
  letter-spacing: 1px;
}
@media (min-width: 48em) {
  .signature {
    font-size: 22px;
  }
}

.burger-menu {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 102;
  width: 30px;
  cursor: pointer;
}
.burger-menu:hover .line.l-1, .burger-menu:hover .line.l-2, .burger-menu:hover .line.l-3, .burger-menu:hover .line.l-4 {
  width: 90%;
}
.burger-menu .line {
  background: #fff;
  height: 2px;
  margin-bottom: 4px;
  display: block;
  margin-right: 0;
  margin-left: auto;
  transition: 0.4s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.burger-menu .line.l-1 {
  width: 65%;
}
.burger-menu .line.l-2 {
  width: 100%;
}
.burger-menu .line.l-3 {
  width: 50%;
}
.burger-menu .line.l-4 {
  width: 65%;
}
@media (min-width: 48em) {
  .burger-menu {
    top: 40px;
    right: 50px;
  }
}

.open-menu {
  overflow: hidden;
  width: 100%;
}
.open-menu .burger-menu .line.l-2, .open-menu .burger-menu .line.l-3 {
  opacity: 0;
}
.open-menu .burger-menu .line.l-1, .open-menu .burger-menu .line.l-4 {
  width: 100%;
  transform-origin: center;
}
.open-menu .burger-menu .line.l-1 {
  transform: rotate(45deg);
  top: 8px;
}
.open-menu .burger-menu .line.l-4 {
  transform: rotate(-45deg);
  top: -10px;
}

.nav-container {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background: #16097b;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
}
.open-menu .nav-container {
  opacity: 1;
  visibility: visible;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
}
.nav-container .menu-logo {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 10;
}
@media (min-width: 48em) {
  .nav-container .menu-logo {
    top: 50px;
    left: 50px;
  }
}
.nav-container .table {
  width: 100%;
  height: 100%;
}
.nav-container .table .cell {
  height: 100%;
  vertical-align: middle;
}
.nav-container:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url(/wp-content/themes/labelleintention/assets/img/layout//bg_dots.png) repeat 30px 30px;
  background-size: 30px 30px;
  opacity: 0.25;
  z-index: 3;
  pointer-events: none;
}
.nav-container .nav-main {
  z-index: 2;
}
.nav-container .nav-main:before {
  content: "";
  display: block;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(/wp-content/themes/labelleintention/assets/img/layout//circle_header.png) no-repeat center center;
  background-size: 200px 200px;
  z-index: 0;
}
.nav-container .nav-main ul {
  z-index: 10;
}
.nav-container .nav-main li {
  width: 100%;
  text-align: center;
}
.nav-container .nav-main a {
  text-decoration: none;
  color: #fff;
  text-align: center;
  font-size: 30px;
  line-height: 50px;
  font-weight: 400;
  font-family: "Circular", "Helvetica Neue", Arial, sans-serif;
  display: inline-block;
  opacity: 0.2;
  transition: 0.6s all cubic-bezier(0.215, 0.61, 0.355, 1);
}
.nav-container .nav-main a:hover, .nav-container .nav-main a.active {
  opacity: 1;
}
@media (min-width: 48em) {
  .nav-container .nav-main:before {
    width: 536px;
    height: 536px;
    background-size: 536px 536px;
  }
  .nav-container .nav-main a {
    font-size: 70px;
    line-height: 90px;
  }
}

.social-container.left {
  left: 50px;
}
.social-container.right {
  right: 50px;
  left: auto;
}
.social-container > span {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 12px;
  margin-bottom: 10px;
  display: block;
}
.social-container svg {
  top: 2px;
}

.nav-container .social-container {
  position: absolute;
  bottom: 40px;
  z-index: 20;
}
@media (max-width: 30em) {
  .nav-container .left {
    width: 100%;
    text-align: center;
    left: auto;
  }
  .nav-container .right {
    display: none;
  }
}

.social-list {
  border: 1px solid #fff;
  display: inline-block;
}
.social-list li {
  border-right: 1px solid #fff;
  float: left;
  vertical-align: middle;
}
.social-list li:last-child {
  border-right: none;
}
.social-list li a {
  width: 50px;
  height: 50px;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
  transition: 0.6s all cubic-bezier(0.215, 0.61, 0.355, 1);
}
.social-list li a i {
  top: 1px;
}
.social-list li a svg {
  fill: #fff;
  transition: 0.6s all cubic-bezier(0.215, 0.61, 0.355, 1);
}
.social-list li a:hover {
  background: #fff;
}
.social-list li a:hover svg {
  fill: #16097b;
}

@media (max-width: 62em) {
  .single .main-header {
    height: auto;
  }
}

/*------------------------------------*\
    Footer
\*------------------------------------*/
#form-nl {
  width: 100%;
  margin: 0 auto;
}
#form-nl input {
  height: 60px;
  background: #efeff2;
  padding: 0 30px;
  width: 100%;
  max-width: 100%;
  margin-right: 40px;
  float: left;
  font-size: 16px;
}
#form-nl input::placeholder {
  color: #1f2020;
  font-weight: 300;
}
#form-nl button {
  background: #1f2020;
  color: #fff;
  text-align: center;
  width: 100%;
}
#form-nl button:hover {
  background: #16097b;
}
@media (min-width: 48em) {
  #form-nl {
    max-width: 800px;
  }
  #form-nl button {
    float: right;
    width: auto;
  }
  #form-nl input {
    max-width: 480px;
  }
}

footer {
  z-index: 2;
}
footer .footer-inner {
  padding: 0 2%;
}
footer .signature {
  text-align: center;
  width: 20%;
  display: block;
  margin: 30px auto 0 auto;
  font-size: 22px;
}
footer .social-container {
  width: 40%;
}
footer .social-container.f-right {
  text-align: right;
}
@media (max-width: 30em) {
  footer .social-container {
    width: 100%;
    float: none;
    text-align: center !important;
    left: auto;
    right: auto;
  }
  footer .signature {
    float: none;
    width: 100%;
    margin-bottom: 30px;
  }
}
@media (min-width: 62em) {
  footer .footer-inner {
    padding: 0 50px;
  }
  footer .signature {
    width: 50%;
    font-size: 28px;
  }
  footer .social-container {
    width: 25%;
  }
}

/*------------------------------------*\
		Pictogrammes
\*------------------------------------*/
.icon_sm.icon_hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  background: #fff;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.icon_sm.icon_hover svg {
  fill: #333;
}
.icon_sm,
.icon_sm svg {
  display: block;
  width: 4.93em;
  height: 0.79em;
}
.icon_sm svg {
  fill: #999;
}

.icon_scrolldown, .icon_scrolldown svg {
  width: 9px;
  height: 31px;
}

.icon_facebook, .icon_facebook svg {
  width: 8px;
  height: 18px;
}

.icon_twitter, .icon_twitter svg {
  width: 42px;
  height: 38px;
}

.icon_instagram, .icon_instagram svg {
  width: 20px;
  height: 20px;
}

.icon_youtube, .icon_youtube svg {
  width: 34px;
  height: 20px;
}

.icon_spotify, .icon_spotify svg {
  width: 17px;
  height: 17px;
}

.icon_soundcloud, .icon_soundcloud svg {
  width: 50px;
  height: 21px;
}

.icon_deezer, .icon_deezer svg {
  width: 34px;
  height: 20px;
}

/*------------------------------------*\
    Buttons
\*------------------------------------*/
.link {
  text-transform: uppercase;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: 5px;
  font-weight: 400;
  transition: 0.6s opacity cubic-bezier(0.215, 0.61, 0.355, 1);
}
.link:hover {
  opacity: 0.6;
}

.btn, .gform_footer [type=submit], #form-nl button {
  height: 60px;
  line-height: 60px;
  text-transform: uppercase;
  font-family: "Circular", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  padding: 0 60px;
  font-size: 12px;
  letter-spacing: 4px;
  display: inline-block;
  text-decoration: none;
  transition: 0.6s color cubic-bezier(0.215, 0.61, 0.355, 1), 0.6s background cubic-bezier(0.215, 0.61, 0.355, 1);
  border: 1px solid currentColor;
}

.btn.bg-black:hover, .gform_footer .bg-black[type=submit]:hover, #form-nl button.bg-black:hover {
  background: white;
  color: #1f2020;
}

.btn.bg-white:hover, .gform_footer .bg-white[type=submit]:hover, #form-nl button.bg-white:hover {
  background: #1f2020;
  color: white;
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 50px;
  background: #16097b;
  z-index: 99;
  transform: translateY(-100%);
  transition: 0.3s transform cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media (max-width: 48em) {
  .sticky {
    padding: 15px 40px;
  }
}

.sticky--open {
  transform: translateY(0);
}

.sticky__logo {
  display: block;
}

.sticky__burger-menu {
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.breadcrumb {
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-top: 50px;
}
@media (min-width: 62em) {
  .breadcrumb {
    margin-top: 150px;
  }
}

.breadcrumb__item {
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 7px;
}
.breadcrumb__item + .breadcrumb__item:before {
  content: "<";
  display: inline-block;
  margin: 0 15px 0 11px;
  color: #282828;
}
@media (min-width: 62em) {
  .breadcrumb__item {
    font-size: 18px;
  }
  .breadcrumb__item:before {
    margin: 0 10px 0 5px;
  }
}

.breadcrumb__item--last {
  color: #282828;
}

.breadcrumb__link {
  text-decoration: none;
  color: #b4b4b4;
  transition: 0.6s color cubic-bezier(0.19, 1, 0.22, 1);
}
.breadcrumb__link:hover {
  color: #282828;
}

.page-id-9 .breadcrumb {
  margin-bottom: 50px;
}

.intro {
  margin-bottom: 75px;
}
.intro p {
  font-size: 20px;
  line-height: 30px;
  font-family: "Circular", "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  color: #1f2020;
}
.intro p:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 48em) {
  .intro p {
    font-size: 24px;
    line-height: 40px;
  }
}
@media (min-width: 62em) {
  .intro {
    margin-bottom: 100px;
  }
  .intro p {
    font-size: 30px;
    line-height: 50px;
  }
}

.artist-wrapper {
  padding: 50px 0;
}
@media (min-width: 62em) {
  .artist-wrapper {
    padding: 100px 0;
  }
}

.artist-container {
  display: block;
}
.artist-container:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(/wp-content/themes/labelleintention/assets/img/layout//bg_dots_blue.png) repeat top left;
  background-size: 30px 30px;
  z-index: 2;
  top: 40px;
  right: 40px;
  transition: 0.2s z-index cubic-bezier(0.215, 0.61, 0.355, 1);
}
.right .artist-container:before {
  left: 40px;
  right: auto;
}
.artist-container + .artist-container {
  margin-top: 100px;
}
@media (min-width: 62em) {
  .artist-container + .artist-container {
    margin-top: 200px;
  }
}

.artist-pic {
  display: block;
  transition: 0.8s all cubic-bezier(0.215, 0.61, 0.355, 1);
}

.artist-infos {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translate3d(50%, -50%, 20px);
  right: 0;
  display: block;
  transition: 1s transform cubic-bezier(0.215, 0.61, 0.355, 1);
}
.right .artist-infos {
  left: 50%;
  right: auto;
  transform: translate3d(-50%, -50%, 20px);
  margin-left: -15%;
  width: 100%;
}

.artist-genre {
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 5px;
  color: #282828;
  font-weight: 300;
  margin-bottom: 10px;
  display: block;
}
@media (min-width: 48em) {
  .artist-genre {
    font-size: 16px;
  }
}

.artist-name {
  font-size: 40px;
  line-height: 45px;
  text-align: center;
  color: #1f2020;
}
@media (min-width: 48em) {
  .artist-name {
    font-size: 90px;
    line-height: 95px;
  }
}
@media (min-width: 62em) {
  .artist-name {
    font-size: 130px;
    line-height: 115px;
  }
}

.artist-count {
  color: #16097b;
  font-family: "Gorgeous", Arial, serif;
  font-size: 80px;
  position: absolute;
  top: 0;
  z-index: 1;
  line-height: 120px;
  margin-top: -68px;
  left: 40px;
  letter-spacing: 10px;
  transition: 0.7s transform cubic-bezier(0.215, 0.61, 0.355, 1);
}
.right .artist-count {
  right: 40px;
  left: auto;
}
@media (min-width: 62em) {
  .artist-count {
    font-size: 120px;
    margin-top: -48px;
  }
}

.artist-container:hover .artist-infos {
  transform: translate3d(60%, -50%, 20px);
}
.artist-container:hover .artist-pic {
  transform: translate3d(10%, 0, 0);
}
.artist-container:hover .artist-count {
  transform: translate3d(20%, 0, 0);
}
.artist-container:hover.right .artist-infos {
  transform: translate3d(-60%, -50%, 20px);
}
.artist-container:hover.right .artist-pic {
  transform: translate3d(-10%, 0, 0);
}
.artist-container:hover.right .artist-count {
  transform: translate3d(-20%, 0, 0);
}
.artist-container:hover:before {
  z-index: 0;
}

.video-container iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.thumb-video-play {
  cursor: pointer;
  transition: 0.8s all cubic-bezier(0.19, 1, 0.22, 1);
}
.thumb-video-play:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #16097b;
  opacity: 0.5;
  z-index: 1;
}
.thumb-video-play:hover .play-btn {
  transform: scale3d(0.95, 0.95, 0.95) translate3d(-50%, -50%, 0);
}
.thumb-video-play.invisible {
  opacity: 0;
}

.play-btn {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 2px solid white;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 2;
  transition: 0.3s all cubic-bezier(0.215, 0.61, 0.355, 1);
  transform-origin: left top;
}
.play-btn:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-color: transparent transparent transparent white;
  border-style: solid;
  border-width: 17.5px 0 17.5px 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -12px;
  margin-top: -18px;
}
@media (max-width: 62em) {
  .play-btn {
    width: 100px;
    height: 100px;
  }
  .play-btn:before {
    width: 0;
    height: 0;
    border-color: transparent transparent transparent white;
    border-style: solid;
    border-width: 15px 0 15px 25px;
    margin-left: -10px;
    margin-top: -15px;
  }
}

.artist-info {
  text-align: center;
  width: 100%;
}

.single-title {
  font-size: 40px;
  line-height: 45px;
}
@media (min-width: 48em) {
  .single-title {
    font-size: 90px;
    line-height: 95px;
  }
}
@media (min-width: 62em) {
  .single-title {
    font-size: 124px;
    line-height: 120px;
  }
}

.single-subtitle {
  font-size: 22px;
}

.slider {
  z-index: 2;
  position: absolute;
}
.slider .slick-list {
  overflow: inherit;
  padding: 0 !important;
}
.slider .slide {
  width: 320px;
  max-width: 520px;
  margin-right: 20px;
}
@media (min-width: 62em) {
  .slider .slide {
    width: 100%;
    max-width: 721px;
    margin-right: 110px;
  }
}

.slider button {
  overflow: hidden;
  text-indent: -9999px;
  display: inline-block;
  color: white;
  width: 30px;
  height: 20px;
  top: 15px;
  transition: 0.3s transform cubic-bezier(0.215, 0.61, 0.355, 1);
}

.slick-prev {
  background: url(/wp-content/themes/labelleintention/assets/svg/layout/arrow-left.svg) no-repeat top left;
  margin-right: 20px;
}
.slick-prev:not(.slick-disabled):hover {
  transform: translateX(-5px);
}

.slick-next {
  background: url(/wp-content/themes/labelleintention/assets/svg/layout/arrow-right.svg) no-repeat top left;
}
.slick-next:not(.slick-disabled):hover {
  transform: translateX(5px);
}

.slick-disabled {
  opacity: 0.1;
}

.artist-quote {
  text-align: center;
  color: #282828;
  margin-bottom: 80px;
}
.artist-quote blockquote {
  max-width: 700px;
  margin: 0 auto;
  font-weight: bold;
  font-style: italic;
  font-size: 24px;
  line-height: 40px;
}
.artist-quote blockquote + p {
  text-transform: uppercase;
  letter-spacing: 2px;
}

.last-release-pic {
  display: block;
  max-width: 480px;
  margin: 0 auto 120px auto;
}
.last-release-pic:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(/wp-content/themes/labelleintention/assets/img/layout//bg_dots_blue.png) repeat top left;
  background-size: 30px 30px;
  z-index: 2;
  top: 55px;
  left: 55px;
}

#social-press {
  z-index: 2;
}

#related-artists-container {
  z-index: 2;
}

.half-col {
  width: 50%;
  float: left;
}

.related-block {
  display: block;
}
.related-block .related-title {
  position: absolute;
  z-index: 2;
  text-align: center;
  width: 100%;
  display: inline-block;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #282828;
  font-size: 50px;
  transition: 0.4s all cubic-bezier(0.19, 1, 0.22, 1);
}
.related-block:after {
  content: "";
  display: block;
  background: #16097b;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 0;
  opacity: 0;
  transition: 0.4s all cubic-bezier(0.19, 1, 0.22, 1);
}
.related-block:hover .related-title {
  color: white;
}
.related-block:hover:after {
  opacity: 0.4;
}

#social-press .press-container,
#social-press .social-container {
  text-align: center;
}
#social-press .press-container > span,
#social-press .social-container > span {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 12px;
  display: block;
  margin-bottom: 30px;
}

@media (max-width: 48em) {
  .social-container--artist {
    margin-bottom: 40px;
  }
}

.container .row:last-of-type .content {
  margin-bottom: 0;
}

form input[type=text],
form input[type=email],
form input[type=tel] {
  background: #efeff2;
  width: 100%;
  height: 60px;
  margin-bottom: 30px;
  padding: 0 20px;
  font-size: 18px;
  font-weight: 400;
}
form input[type=text]::placeholder,
form input[type=email]::placeholder,
form input[type=tel]::placeholder {
  color: #1f2020;
  font-weight: 300;
}
form input[type=text] + label,
form input[type=email] + label,
form input[type=tel] + label {
  position: absolute;
  top: 0;
  left: 20px;
}
form textarea {
  background: #efeff2;
  border: none;
  width: 100%;
  height: 300px;
  padding: 20px;
  font-size: 18px;
  font-weight: 400;
  font-family: "Circular", "Helvetica Neue", Arial, sans-serif;
}
form .gfield {
  width: 48%;
  float: left;
  margin-bottom: 60px;
}
form .gfield:nth-child(2n+1) {
  margin-right: 2%;
}
form .gfield:nth-child(2n+3), form .gfield:last-child {
  clear: both;
}
form .gfield:last-child {
  width: 100%;
}
form .gfield_label {
  display: none;
}
form .ginput_container_phone,
form .ginput_container_text,
form .ginput_container_email {
  height: 60px;
}

.gform_description {
  margin-bottom: 80px;
  display: block;
  font-size: 30px;
  font-family: "Circular", "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
  color: #1f2020;
  line-height: 50px;
}

.gform_footer {
  text-align: center;
}
.gform_footer [type=submit] {
  background: #1f2020;
  color: white;
  cursor: pointer;
}
.gform_footer [type=submit]:hover {
  background: #16097b;
}

.validation_error {
  margin-bottom: 40px;
}

.validation_message {
  color: #dc2626;
  position: absolute;
  font-size: 12px;
  left: 0;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.normal-slider {
  z-index: 1;
  max-width: 371px;
  margin: 0 auto;
}
.normal-slider .slide-caption {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transform: translateY(40px);
  transition: 0.6s all cubic-bezier(0.23, 1, 0.32, 1);
  transition-delay: 0.4s;
}
.normal-slider .slide-caption .table {
  width: 100%;
  height: 100%;
}
.normal-slider .slide-caption .cell {
  vertical-align: middle;
}
.normal-slider .slide {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
}
.normal-slider .slide img {
  width: 100%;
  height: auto;
  display: block;
}
.normal-slider .slide strong {
  font-size: 72px;
  font-weight: 600;
}
.normal-slider .slide.slick-active .slide-caption {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.normal-slider .slick-dots {
  width: 100%;
  position: absolute;
  bottom: 50px;
  margin-bottom: 0;
  text-align: center;
}
.normal-slider .slick-dots li {
  display: inline;
  margin: 0 5px;
}
.normal-slider .slick-dots li button {
  width: 15px;
  height: 15px;
  overflow: hidden;
  border-radius: 50%;
  background: #f4f7fb;
  text-indent: -9999px;
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.normal-slider .slick-dots li:not(.slick-active):hover button {
  transform: translateY(-2px);
}
.normal-slider .slick-dots li.slick-active button {
  background: #86bc25;
}
@media (min-width: 48em) {
  .normal-slider .slide strong {
    font-size: 40px;
  }
  .normal-slider .slick-dots {
    bottom: 20px;
  }
}
@media (min-width: 64em) {
  .normal-slider .slide strong {
    font-size: 72px;
  }
  .normal-slider .slick-dots {
    bottom: 50px;
  }
}