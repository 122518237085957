/*
Typographie
===========

Règles de base pour tout le site (h1, h2, blockquote, etc).

-->    http://type-scale.com/    <--

Pratique pour générer des rythmes typographiques rapidement
*/


body {
	line-height: 1.45;
}

p,
ol,
blockquote {
	margin-bottom: 1.3em;
}

ul{
	margin-bottom: 0;
}

h1,
h2,
h3,
h4 {
	margin: 0;
	line-height: 1.2;
	font-weight: 700;
}

// h1 {
// 	margin-top: 0;
// 	font-size: 3.157em;
// }

// h2 {
// 	font-size: 2.369em;
// }

// h3 {
// 	font-size: 1.777em;
// }

// h4 {
// 	font-size: 1.333em;
// }

small {
	font-size: 0.75em;
}

sup { 
    vertical-align: super;
    font-size: 0.75em;
}