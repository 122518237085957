/*------------------------------------*\
    Header
\*------------------------------------*/

.main-header {
  // GLOBAL //
  background: $blue;
  height: 60vh;
  width: 100%;
  z-index: 2;
  max-height: 780px;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    background: url(#{$img-dir}/bg_dots.png) repeat top left;
    background-size: 30px 30px;

    opacity: 0.25;
    z-index: 1;
  }

  &:after {
    content: '';
    display: block;
    width: 200px;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url(#{$img-dir}/circle_header.png) no-repeat center center;
    background-size: 200px 200px;
    z-index: -1;
  }

  .table {
    height: 100%;
    width: 100%;
    .cell {
      height: 100%;
      vertical-align: middle;
    }
  }

  // END GLOBAL //

  $w_left: 305px;

  // SINGLE //

  .col-left {
    padding: 30px;
    z-index: 1;

    .col-left-inner {
      height: 100%;
    }

    .btn {
      width: 205px;
      padding: 0;
      text-align: center;
      margin-top: 30px;
    }

    @media #{md("medium")} {
      padding: 50px;

      .col-left-bottom-container {
        position: absolute;
        bottom: 0;
      }
    }
  }
  .col-right {
    height: 300px;

    .col-right-inner {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;

      .col-right-content {
        z-index: 1;
        height: 100%;

        .table,
        .cell {
          height: 100%;
        }
        .cell {
          vertical-align: middle;
        }
      }

      &:after {
        content: '';
        display: block;
        background: $blue;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 0;
        opacity: 0.4;
      }
    }
  }

  .header-title {
    text-align: center;
    width: 100%;
    font-size: 60px;
    line-height: 64px;

    @media #{md("small")} {
      font-size: 100px;
      line-height: 96px;
    }
  }

  @media #{md("small")} {
    .col-right {
      height: 450px;
    }
  }

  @media #{md("medium")} {
    max-height: none;
    height: 100vh;

    .col-left {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: $w_left;
    }
    .col-right {
      position: absolute;
      top: 0;
      left: $w_left;
      width: calc(100% - 305px);
      height: 100%;
    }

    &:after {
      width: 536px;
      height: 536px;
      background-size: 536px 536px;
    }
  }

  @media #{md("large")} {
    .header-title {
      font-size: 124px;
      line-height: 120px;
    }
  }
}

.col-left-bottom-container {
  @media #{md("medium", "max")} {
    text-align: center;
  }
}

.logo-small {
  z-index: 102;
  display: block;
  position: absolute;
  top: 30px;
  left: 30px;
  width: 60px;

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  @media #{md("small")} {
    top: 50px;
    left: 50px;
    width: 88px;
  }
}

.main-logo-container {
  width: 90%;
  max-width: 150px;
  margin: 0 auto;

  &:before {
    content: '';
    display: block;
    width: 200px;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -100px;
    background: url(#{$img-dir}/circle_header.png) no-repeat center center;
    background-size: 200px 200px;
  }

  img {
    width: 100%;
    max-width: 295px;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    display: block;
  }

  @media #{md("small")} {
    &:before {
      background-size: 536px 536px;
    }
    max-width: 420px;
  }
}

.scrollto {
  display: block;
  position: absolute;
  bottom: 0;
  z-index: 2;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 3px;
  font-weight: bold;
  left: 50%;
  margin-left: -50px;
  width: 98px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  &:hover {
    &:after {
      transform: scaleY(0.5) translateY(10px);
    }
  }

  span {
    margin-bottom: 10px;
    display: block;
  }

  &:after {
    content: '';
    display: block;
    width: 1px;
    background: $white;
    height: 20px;
    opacity: 0.5;
    margin: 0 auto;
    transform-origin: bottom;
    transition: 0.2s all $out-circ;
  }

  @media #{md("small")} {
    font-size: 12px;
    span {
      margin-bottom: 20px;
    }
    &:after {
      height: 80px;
    }
  }
}

.main-logo {
  margin-bottom: 30px;
}

.signature {
  font-size: 20px;
  text-align: center;
  letter-spacing: 1px;

  @media #{md("small")} {
    font-size: 22px;
  }
}

.burger-menu {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 102;
  width: 30px;
  cursor: pointer;

  &:hover {
    .line {
      &.l-1,
      &.l-2,
      &.l-3,
      &.l-4 {
        width: 90%;
      }
    }
  }

  .line {
    background: $white;
    height: 2px;
    margin-bottom: 4px;
    display: block;
    margin-right: 0;
    margin-left: auto;
    transition: 0.4s all $out-back;

    &.l-1 {
      width: 65%;
    }
    &.l-2 {
      width: 100%;
    }
    &.l-3 {
      width: 50%;
    }
    &.l-4 {
      width: 65%;
    }
  }

  @media #{md("small")} {
    top: 40px;
    right: 50px;
  }
}

.open-menu {
  overflow: hidden;
  // position: fixed;
  width: 100%;

  .burger-menu {
    .line {
      &.l-2,
      &.l-3 {
        opacity: 0;
      }

      &.l-1,
      &.l-4 {
        width: 100%;
        transform-origin: center;
      }

      &.l-1 {
        transform: rotate(45deg);
        top: 8px;
      }

      &.l-4 {
        transform: rotate(-45deg);
        top: -10px;
      }
    }
  }
}

.nav-container {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background: $blue;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: 1s all $out-circ;

  .open-menu & {
    opacity: 1;
    visibility: visible;
    transition: 1s all $out-circ;
  }

  .menu-logo {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 10;

    @media #{md("small")} {
      top: 50px;
      left: 50px;
    }
  }

  .table {
    width: 100%;
    height: 100%;
    .cell {
      height: 100%;
      vertical-align: middle;
    }
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    background: url(#{$img-dir}/bg_dots.png) repeat 30px 30px;
    background-size: 30px 30px;

    opacity: 0.25;
    z-index: 3;
    pointer-events: none;
  }

  .nav-main {
    z-index: 2;

    &:before {
      content: '';
      display: block;
      width: 200px;
      height: 200px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url(#{$img-dir}/circle_header.png) no-repeat center center;
      background-size: 200px 200px;
      z-index: 0;
    }

    ul {
      z-index: 10;
    }

    li {
      width: 100%;
      text-align: center;
    }
    a {
      text-decoration: none;
      color: $white;
      text-align: center;
      font-size: 30px;
      line-height: 50px;
      font-weight: 400;
      font-family: $sans;
      display: inline-block;
      opacity: 0.2;
      transition: 0.6s all $out-cubic;

      &:hover,
      &.active {
        opacity: 1;
      }
    }
  }

  @media #{md("small")} {
    .nav-main {
      &:before {
        width: 536px;
        height: 536px;
        background-size: 536px 536px;
      }
      a {
        font-size: 70px;
        line-height: 90px;
      }
    }
  }
}

.social-container {
  &.left {
    left: 50px;
  }
  &.right {
    right: 50px;
    left: auto;
  }
  > span {
    color: $white;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 12px;
    margin-bottom: 10px;
    display: block;
  }

  svg {
    top: 2px;
  }
}

.nav-container {
  .social-container {
    position: absolute;
    bottom: 40px;
    z-index: 20;
  }

  @media #{md("smaller", "max")} {
    .left {
      width: 100%;
      text-align: center;
      left: auto;
    }
    .right {
      display: none;
    }
  }
}

.social-list {
  border: 1px solid $white;
  display: inline-block;

  @extend .cf;

  li {
    border-right: 1px solid $white;
    float: left;
    vertical-align: middle;
    &:last-child {
      border-right: none;
    }
    a {
      width: 50px;
      height: 50px;
      vertical-align: middle;
      text-align: center;
      display: table-cell;
      transition: 0.6s all $out-cubic;

      i {
        top: 1px;
      }

      svg {
        fill: #fff;
        transition: 0.6s all $out-cubic;
      }

      &:hover {
        background: $white;
        svg {
          fill: $blue;
        }
      }
    }
  }
}

.single {
  .main-header {
    @media #{md("medium", "max")} {
      height: auto;
    }
  }
}
