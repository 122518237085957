/*------------------------------------*/
/*    Fontface declaration
/*------------------------------------*/

@font-face {
  font-family: 'Circular';
  src: url('../../static/fonts/CircularStd-Medium.woff2') format('woff2'),
    url('../../static/fonts/CircularStd-Medium.woff') format('woff'),
    url('../../static/fonts/CircularStd-Medium.otf') format('opentype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Circular';
  src: url('../../static/fonts/CircularStd-Book.woff2') format('woff2'),
    url('../../static/fonts/CircularStd-Book.woff') format('woff'),
    url('../../static/fonts/CircularStd-Book.otf') format('opentype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Circular';
  src: url('../../static/fonts/CircularStd-Bold.woff2') format('woff2'),
    url('../../static/fonts/CircularStd-Bold.woff') format('woff'),
    url('../../static/fonts/CircularStd-Bold.otf') format('opentype');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Crimson';
  src: url('../../static/fonts/CrimsonText-Italic.woff2') format('woff2'),
    url('../../static/fonts/CrimsonText-Italic.woff') format('woff'),
    url('../../static/fonts/CrimsonText-Italic.otf') format('opentype');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'Gorgeous';
  src: url('../../static/fonts/Gorgeous-Bold.woff2') format('woff2'),
    url('../../static/fonts/Gorgeous-Bold.woff') format('woff'),
    url('../../static/fonts/Gorgeous-Bold.otf') format('opentype');
  font-style: normal;
  font-weight: 400;
}
