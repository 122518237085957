.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 50px;
  background: $blue;
  z-index: 99;
  transform: translateY(-100%);
  transition: 0.3s transform $out-cubic;

  @media #{md("small", "max")} {
    padding: 15px 40px;
  }
}

.sticky--open {
  transform: translateY(0);
}

.sticky__logo {
  display: block;
}

.sticky__burger-menu {
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
