.intro {
  margin-bottom: 75px;

  p {
    font-size: 20px;
    line-height: 30px;

    font-family: $sans;
    font-weight: 500;
    color: $black;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media #{md("small")} {
    p {
      font-size: 24px;
      line-height: 40px;
    }
  }

  @media #{md("medium")} {
    margin-bottom: 100px;
    p {
      font-size: 30px;
      line-height: 50px;
    }
  }
}

.artist-wrapper {
  padding: 50px 0;

  @media #{md("medium")} {
    padding: 100px 0;
  }
}

.artist-container {
  display: block;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(#{$img-dir}/bg_dots_blue.png) repeat top left;
    background-size: 30px 30px;
    z-index: 2;
    top: 40px;
    right: 40px;
    transition: 0.2s z-index $out-cubic;
  }

  .right & {
    &:before {
      left: 40px;
      right: auto;
    }
  }

  & + .artist-container {
    margin-top: 100px;
  }

  @media #{md("medium")} {
    & + .artist-container {
      margin-top: 200px;
    }
  }
}

.artist-pic {
  display: block;
  transition: 0.8s all $out-cubic;
}

.artist-infos {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translate3d(50%, -50%, 20px);
  right: 0;
  display: block;
  transition: 1s transform $out-cubic;

  .right & {
    left: 50%;
    right: auto;
    transform: translate3d(-50%, -50%, 20px);
    margin-left: -15%;
    width: 100%;
  }
}

.artist-genre {
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 5px;
  color: $black-light;
  font-weight: 300;
  margin-bottom: 10px;
  display: block;

  @media #{md("small")} {
    font-size: 16px;
  }
}

.artist-name {
  font-size: 40px;
  line-height: 45px;
  text-align: center;

  color: $black;

  @media #{md("small")} {
    font-size: 90px;
    line-height: 95px;
  }

  @media #{md("medium")} {
    font-size: 130px;
    line-height: 115px;
  }
}

.artist-count {
  color: $blue;
  font-family: $nbrFont;
  font-size: 80px;
  position: absolute;
  top: 0;
  z-index: 1;
  line-height: 120px;
  margin-top: -68px;
  left: 40px;
  letter-spacing: 10px;
  transition: 0.7s transform $out-cubic;

  .right & {
    right: 40px;
    left: auto;
  }

  @media #{md("medium")} {
    font-size: 120px;
    margin-top: -48px;
  }
}

.artist-container:hover {
  .artist-infos {
    transform: translate3d(60%, -50%, 20px);
  }
  .artist-pic {
    transform: translate3d(10%, 0, 0);
  }
  .artist-count {
    transform: translate3d(20%, 0, 0);
  }

  &.right {
    .artist-infos {
      transform: translate3d(-60%, -50%, 20px);
    }
    .artist-pic {
      transform: translate3d(-10%, 0, 0);
    }
    .artist-count {
      transform: translate3d(-20%, 0, 0);
    }
  }

  &:before {
    z-index: 0;
  }
}

.video-container {
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.thumb-video-play {
  cursor: pointer;
  transition: 0.8s all $out-expo;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $blue;
    opacity: 0.5;
    z-index: 1;
  }

  &:hover {
    .play-btn {
      transform: scale3d(0.95, 0.95, 0.95) translate3d(-50%, -50%, 0);
    }
  }

  &.invisible {
    opacity: 0;
  }
}

.play-btn {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 2px solid white;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 2;
  transition: 0.3s all $out-cubic;
  transform-origin: left top;

  &:before {
    content: '';
    display: block;
    @include triangle-right(30px, 35px, white);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    margin-top: -18px;
  }

  @media #{md("medium", "max")} {
    width: 100px;
    height: 100px;

    &:before {
      @include triangle-right(25px, 30px, white);
      margin-left: -10px;
      margin-top: -15px;
    }
  }
}
