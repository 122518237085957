.artist-info {
  text-align: center;
  width: 100%;
}
.single-title {
  font-size: 40px;
  line-height: 45px;

  @media #{md("small")} {
    font-size: 90px;
    line-height: 95px;
  }

  @media #{md("medium")} {
    font-size: 124px;
    line-height: 120px;
  }
}

.single-subtitle {
  font-size: 22px;
}

.slider {
  z-index: 2;
  position: absolute;

  .slick-list {
    overflow: inherit;
    padding: 0 !important;
  }

  .slide {
    width: 320px;
    max-width: 520px;
    margin-right: 20px;
  }

  @media #{md("medium")} {
    .slide {
      width: 100%;
      max-width: 721px;
      margin-right: 110px;
    }
  }
}

.slider {
  button {
    overflow: hidden;
    text-indent: -9999px;
    display: inline-block;
    color: white;
    width: 30px;
    height: 20px;
    top: 15px;
    transition: 0.3s transform $out-cubic;
  }
}

.slick-prev {
  background: url(#{$svg-dir}arrow-left.svg) no-repeat top left;
  margin-right: 20px;

  &:not(.slick-disabled):hover {
    transform: translateX(-5px);
  }
}

.slick-next {
  background: url(#{$svg-dir}arrow-right.svg) no-repeat top left;

  &:not(.slick-disabled):hover {
    transform: translateX(5px);
  }
}

.slick-disabled {
  opacity: 0.1;
}

.artist-quote {
  text-align: center;
  color: $black-light;
  margin-bottom: 80px;

  blockquote {
    max-width: 700px;
    margin: 0 auto;

    font-weight: bold;
    font-style: italic;
    font-size: 24px;
    line-height: 40px;

    & + p {
      text-transform: uppercase;
      letter-spacing: 2px;
    }
  }
}

.last-release-pic {
  display: block;
  max-width: 480px;
  margin: 0 auto 120px auto;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(#{$img-dir}/bg_dots_blue.png) repeat top left;
    background-size: 30px 30px;
    z-index: 2;
    top: 55px;
    left: 55px;
  }
}

#social-press {
  z-index: 2;
}

#related-artists-container {
  z-index: 2;
}

.half-col {
  width: 50%;
  float: left;
}

.related-block {
  display: block;

  .related-title {
    position: absolute;
    z-index: 2;
    text-align: center;
    width: 100%;
    display: inline-block;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: $black-light;
    font-size: 50px;

    transition: 0.4s all $out-expo;
  }

  &:after {
    content: '';
    display: block;
    background: #16097b;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 0;
    opacity: 0;
    transition: 0.4s all $out-expo;
  }

  &:hover {
    .related-title {
      color: white;
    }
    &:after {
      opacity: 0.4;
    }
  }
}

#social-press {
  .press-container,
  .social-container {
    text-align: center;
    > span {
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 3px;
      font-size: 12px;
      display: block;
      margin-bottom: 30px;
    }
  }
}

.social-container--artist {
  @media #{md("small", "max")} {
    margin-bottom: 40px;
  }
}

.container {
  .row:last-of-type {
    .content {
      margin-bottom: 0;
    }
  }
}
