form {
  input[type='text'],
  input[type='email'],
  input[type='tel'] {
    background: $grey;
    width: 100%;
    height: 60px;
    margin-bottom: 30px;
    padding: 0 20px;
    font-size: 18px;
    font-weight: 400;

    &::placeholder {
      color: $black;
      font-weight: 300;
    }

    & + label {
      position: absolute;
      top: 0;
      left: 20px;
    }
  }

  textarea {
    background: $grey;
    border: none;
    width: 100%;
    height: 300px;
    padding: 20px;
    font-size: 18px;
    font-weight: 400;
    font-family: $sans;
  }

  .gform_body {
    @extend .cf;
  }

  .gfield {
    width: 48%;
    float: left;
    margin-bottom: 60px;

    &:nth-child(2n + 1) {
      margin-right: 2%;
    }

    &:nth-child(2n + 3),
    &:last-child {
      clear: both;
    }

    &:last-child {
      width: 100%;
    }
  }

  .gfield_label {
    display: none;
  }

  .ginput_container_phone,
  .ginput_container_text,
  .ginput_container_email {
    height: 60px;
  }
}

.gform_description {
  margin-bottom: 80px;
  display: block;
  font-size: 30px;
  font-family: $sans;
  font-weight: 600;
  color: $black;
  line-height: 50px;
}

.gform_footer {
  text-align: center;

  [type='submit'] {
    @extend .btn;
    background: $black;
    color: white;
    cursor: pointer;

    &:hover {
      background: $blue;
    }
  }
}

.validation_error {
  margin-bottom: 40px;
}

.validation_message {
  color: #dc2626;
  position: absolute;
  font-size: 12px;
  left: 0;
}
