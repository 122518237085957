.breadcrumb {
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-top: 50px;

  @media #{md("medium")} {
    margin-top: 150px;
  }
}

.breadcrumb__item {
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 7px;

  & + .breadcrumb__item {
    &:before {
      content: '<';
      display: inline-block;
      margin: 0 15px 0 11px;
      color: $black-light;
    }
  }

  @media #{md("medium")} {
    font-size: 18px;

    &:before {
      margin: 0 10px 0 5px;
    }
  }
}

.breadcrumb__item--last {
  color: $black-light;
}

.breadcrumb__link {
  text-decoration: none;
  color: #b4b4b4;
  transition: 0.6s color $out-expo;

  &:hover {
    color: $black-light;
  }
}

.page-id-9 .breadcrumb {
  margin-bottom: 50px;
}
