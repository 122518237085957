/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}


.normal-slider{
    z-index:1;
    max-width: 371px;
    margin:0 auto;
    .slide-caption{
        position: absolute;
        z-index:10;
        width: 100%;
        height: 100%;
        left:0;
        top: 0;
        visibility: hidden;
        opacity: 0;
        transform:translateY(40px);
        
        transition:.6s all $out-quint;
        transition-delay:.4s;

        .table{
            width: 100%;
            height: 100%;
        }
        .cell{
            vertical-align: middle;
        }

    }
    .slide{
        color:#fff;
        text-align: center;
        text-transform: uppercase;
        z-index:1;
        img{
            width: 100%;
            height:auto;
            display: block;
        }

        strong{
            font-size:72px;
            font-weight:600;
        }
        &.slick-active .slide-caption{
            opacity: 1;
            visibility: visible;
            transform:translateY(0);
        }
    }



    .slick-dots{
        width: 100%;
        position: absolute;
        bottom:50px;
        margin-bottom: 0;
        text-align: center;

        li{
            display: inline;
            margin:0 5px;

            button{
                width: 15px;
                height: 15px;
                overflow: hidden;
                border-radius:50%;
                background: $grey-bg;
                text-indent: -9999px;   
                transition: all 0.4s $out-expo;
            }

            &:not(.slick-active):hover{
                button{
                    transform:translateY(-2px);
                }
            }

            &.slick-active{
                button{
                    background:$green;
                }
            }
        }
    }

    @media #{md("small")} {
        .slide{
            strong{
                font-size:40px;
            }
        }
        .slick-dots{
            bottom: 20px;
        }
    }

    @media #{md("normal")} {
        .slide{
            strong{
                font-size:72px;
            }
        }
        .slick-dots{
            bottom: 50px;
        }
    }
}